import Model from '@/dc-it/models/Model'
import Field from '@/dc-it/types/Field'
import BooleanField from '@/dc-it/types/BooleanField'

export default class Box extends Model {
    endpoint = 'boxes';

    fields = [
      new Field('id', 'Código').disabled(),
      new Field('name', 'Nombre').disabled(),
      new Field('weight', 'Peso', 'number').hide(),
      new Field('height', 'Alto', 'number').hide(),
      new Field('width', 'Ancho', 'number').hide(),
      new Field('length', 'Largo', 'number').hide(),
      new BooleanField('active', 'Activo'),
    ]

    clone = () => Box;
}
